import { createRouter, createWebHistory } from 'vue-router';

import RouteName from './routeName';

const routes = [
  {
    path: '/',
    name: RouteName.Home,
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/card/:id',
    name: RouteName.Card,
    component: () => import('../views/Card.vue'),
  },
  {
    path: '/2023',
    name: RouteName.NewYear,
    component: () => import('../views/2023.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    }

    return {
      top: 0,
      behavior: 'auto',
    };
  },
});

export default router;
