import { createApp } from 'vue';
import { createHead } from '@unhead/vue';
import VueGtag from 'vue-gtag';
import VueHotjar from 'vue-hotjar-next';

import '@varlet/ui/es/style.js';
import '@varlet/touch-emulator';
import VueSocialSharing from 'vue-social-sharing';

import '@/css/main.scss';

import {
  Button, Ripple,
} from '@varlet/ui';

import App from './App.vue';
import router from './router';
import store from './store';

const app = createApp(App);
const head = createHead();

app.use(store);
app.use(router);

app.use(Button).use(Ripple).use(VueSocialSharing).use(head);

app.use(VueGtag, {
  config: { id: 'G-PN0GD7PXMC' },
}, router);

app.use(VueHotjar, {
  id: 3809534,
  isProduction: true,
  snippetVersion: 6,
});

app.directive('click-outside', {
  mounted(el, binding) {
    // eslint-disable-next-line func-names
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

app.mount('#app');
